$(document).ready(function () {
  setTimeout(function () {
    $('.shopping-cart').addClass('active');
    $('.order-online').addClass('active');
    $('.shipped-to').addClass('active');
  }, 1000);
  $('.video-overlay__close').on('click', function () {
    $('.video-overlay').fadeOut('slow');
  })
});

$(document).ready(function () {
  setTimeout(function () {
    $('.video-overlay').addClass('active');
  }, 200);
});
