(function () {

  const setupContactPage = function () {
    if ($('.UserDefinedForm').length < 1) {
      return false;
    }

    console.log("setup ContactPage");

    $(document).ready(function () {
      const href = location.href;
      const split = href.split("#");

      if (split[1] && split[1] == 'distributor') {
        // Clicked through the vendor link, automatically checks the 'distributor' checkbox
        $('.checkbox.distributor').prop('checked', true);
      }
    });

    $(window).on('hashchange', function() {
      const href = location.href;
      const split = href.split("#");

      if (split[1] && split[1] == 'distributor') {
        // Clicked through the vendor link, automatically checks the 'distributor' checkbox
        $('.checkbox.distributor').prop('checked', true);
      }
    });

    const toggleSelectPlaceholder = (el) => {
      $(el).toggleClass('unselected', $(el).val() === '');
    };

    $('form select').each((index, el) => {
      $(el).find("option[value=' ']").prop('value', '');

      toggleSelectPlaceholder(el);
      $(el).on('change', () => {
        toggleSelectPlaceholder(el);
      });
    });

    // Make some fields on contact page required
    $('#UserForm_Form').validate({
      errorLabelContainer: '.error-list',
    });

    $('input.optional').rules('add', {
      required: () => {
        return $('select.has-optional').val() === 'Other';
      },
      messages: {
        required: "'Other (please specify)' is required"
      }
    });

  };

  setupContactPage();

})();
