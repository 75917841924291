(function () {

  let jPM;

  const setupHeader = () => {
    if (jPM) {
      jPM.off();
    }

    jPM = $.jPanelMenu({
      menu: '.site-nav',
      panel: '.canvas-content-panel',
      direction: 'right',
      duration: 400,
      beforeOpen: () => {
        $('html').addClass('menu-opened')
      },
      afterOpen: () => {
        $('#jPanelMenu-menu').css('z-index', '100');
      },
      beforeClose: () => {
        $('#jPanelMenu-menu').css('z-index', '1');
        $('html').removeClass('menu-opened')
      }
    });

    jPM.on();
  };

  setupHeader();

  const $StoreLogos = $('.store-logos .logos');

  const setupStoreLogos = () => {
    $StoreLogos.on('init', function (event, slick) {
      $StoreLogos.css('visibility', 'visible');
    });

    $StoreLogos.slick({
      slidesToShow: 7,
      slidesToScroll: 1,
      speed: 3000,
      cssEase: 'linear',
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 1,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 7,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          }
        }
      ]
    });
  };

  setupStoreLogos();

  const lazySetupStoreLogos = _.debounce(setupStoreLogos, 300);
  $(window).resize(() => {
    $StoreLogos.slick('unslick');
    $StoreLogos.css('visibility', 'hidden');
    lazySetupStoreLogos();
  });

  $(document).on('open.fndtn.offcanvas', '', function () {
    $('.navbar').css('position', 'absolute');
    $('.layout-wrap').css('margin-top', 0);
  });

  // Footer contact form AJAX setup
  const $form = $('form[name=mc-embedded-subscribe-form]');
  $form.on('submit', (e) => {
    $form.find('.mc-response').hide();
  });

  $form.ajaxChimp({
    url: $form.prop('action'),
    callback: (resp) => {
      if (resp.result === 'success') {
        $.ajax({
          type: "POST",
          url: '/blog/NewsSubscribeForm',
          data: {
            Email: $form.find('input[type=email]').val(),
            SecurityID: $form.find('input[name=SecurityID]').val()
          },
          success: function (resp) {
          },
          dataType: 'json'
        });

        $form.find('.mc-response.success').show();
        $form.find('input[type=email]').val('');
      }
      else {
        const $error = $form.find('.mc-response.error');
        if (resp.msg[0] === '0') {
          $error.text('Please enter a valid email address');
        }

        if (resp.msg.indexOf('already subscribed') !== -1) {
          $error.text('This email address is already subscribed');
        }

        $error.show();
      }
    }
  });

  setTimeout(() => {
    $('.site-header').addClass('active');
  }, 200);

  $(document).foundation();
})();
