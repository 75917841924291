// $(document).ready(function () {

// $('.video-image').on("click", function() {
//   var url = $(this).attr("title");
//   console.log(url);
//   $('#' + url).css('display', 'block');
// });

// });

// function setupVideoPopups() {
//   var $popups = $('.js-video-popup')

//   if (!$popups.length) {
//       return
//   }

//   $('.js-video-popup').magnificPopup({
//       delegate: '.js-video-popup',
//       type: 'iframe',
//       closeOnBgClick: false
//   })
// }


// setupVideoPopups()
