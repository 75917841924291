(function () {

  const setupHomePage = function () {
    if ($('.HomePage').length < 1) {
      return false;
    }

    //console.log("setup HomePage");

    const parallaxSections = {
      '.panel-home-intro': '25%',
    };

    const hasParallax = (() => {
      let parallax = false;

      for (let key in parallaxSections) {
        if ($(key).length) {
          parallax = true;
        }
      }

      return parallax;
    })();

    if (hasParallax) {
      const controller = new ScrollMagic.Controller({
        globalSceneOptions: {
          triggerHook: 'onLeave',
          duration: '100%'
        }
      });

      for (let key in parallaxSections) {
        if ($(key).length) {
          new ScrollMagic.Scene({triggerElement: key})
              .setTween(`${key} .panel-image`, {y: parallaxSections[key]})
              .addTo(controller);
        }
      }
    }

    setTimeout(() => {
      $('.panel-home-intro').addClass('active');
    }, 600);
  };

  setupHomePage();

  const setupHeroSliders = () => {
  };

  setupHeroSliders();

  const setupInstagram = () => {
    $('.instagram-wrapper').on('init', function (event, slick) {
      $('.instagram-wrapper .instagram-media').css('visibility', 'visible');
    });

    $('.instagram-wrapper').slick({
      slidesToShow: 5,
      slidesToScroll: 4,
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        }
      ]
    });
  };

  setupInstagram();

  const setupHomepageAnimation = () => {

    // const $first_homepage_slider = $('.hero-images .panel-image:not(.slider-hide)');
    // let $current = $first_homepage_slider;
    //
    // setInterval(() => {
    //   $current.toggleClass('slider-hide');
    //   $current = $current.next();
    //   if (! $current.length) {
    //     $current = $first_homepage_slider;
    //   }
    //   $current.toggleClass('slider-hide');
    // }, 8000);

    $('.hero-sliders').on('init', function (event, slick) {
      $('.hero-sliders').css('visibility', 'visible');
    });

    $('.hero-sliders').on('beforeChange', function(event, slick, currentSlide, nextSlider) {
      $('.hero-images .panel-image:eq(' + currentSlide + ')').addClass('slider-hide');
      $('.hero-images .panel-image:eq(' + nextSlider + ')').removeClass('slider-hide');
    });

    $('.hero-sliders').slick({
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      pauseOnFocus: false,
      pauseOnHover: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
    });

  };

  setupHomepageAnimation();

})();
