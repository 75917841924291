(function () {

  const state = {
    map: null,
    currentInfoWindow: false,
    currentMarker: null,
    locations: {},
    markers: [],
    currentLocation: null,
    bounds: false
  };

  const setupMap = () => {
    const mapStyles = [
      {
        featureType: "all",
        elementType: "all",
        stylers: [
          { hue: "#123e74" },
          { saturation: 50 }
        ]
      },{
        featureType: "road",
        stylers: [
          { visibility: "simplified" },
          { weight: 0.5 }
        ]
      },{
        featureType: "water",
        stylers: [
          { lightness: -11 }
        ]
      },{
        featureType: "poi.park",
        stylers: [
          { hue: "#33ff00" },
          { saturation: -50 }
        ]
      },{
        featureType: "poi.school",
        stylers: [
          { hue: "#0099ff" },
          { saturation: -50 }
        ]
      },{
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
          { visibility: "simplified" },
          { weight: 0.5 }
        ]
      }
    ];

    const center = new google.maps.LatLng(state.userLocation.lat, state.userLocation.lng);

    const mapOptions = {
      styles: mapStyles,
      center,
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      scrollwheel: false
    };

    if ($('html').hasClass('no-touch')) {
      mapOptions.zoomControl = true;
      mapOptions.zoomControlOptions = {
        style: google.maps.ZoomControlStyle.LARGE,
        position: google.maps.ControlPosition.RIGHT_CENTER
      }
    }

    state.map = new google.maps.Map(document.getElementById('google-map'), mapOptions);

    google.maps.event.addListenerOnce(state.map, 'tilesloaded', () => {
      placeMarkers().then((resp) => {
        bindUI();
        placeCurrentMarker(state.userLocation.lat, state.userLocation.lng);

        if (state.bounds) {
          state.map.fitBounds(state.bounds);
        }

        panCenter(state.map.getCenter());
      });
    });
  }

  const panCenter = (latLng) => {
    let offsetX = 0;
    const offsetY = 0;

    if ($(window).width() >= 768) {
      offsetX = 160;
    }

    if ($(window).width() >= 1024) {
      offsetX = 100;
    }

    const point1 = state.map.getProjection().fromLatLngToPoint(
      (latLng instanceof google.maps.LatLng) ? latLng : state.map.getCenter()
    );

    const point2 = new google.maps.Point(
      ((typeof(offsetX) == 'number' ? offsetX : 0) / Math.pow(2, state.map.getZoom())) || 0,
      ((typeof(offsetY) == 'number' ? offsetY : 0) / Math.pow(2, state.map.getZoom())) || 0
    );

    state.map.panTo(state.map.getProjection().fromPointToLatLng(new google.maps.Point(
      point1.x - point2.x,
      point1.y + point2.y
    )));
  }

  const placeMarkers = () => {
    const placed = new Promise((resolve, reject) => {
      for (let id in state.locations) {
        setupMarker(state.locations[id]);
        setupInfoWindow(state.locations[id]);
      }

      resolve();
    });

    return placed;
  }

  const setupMarker = (location) => {
    const marker = new google.maps.Marker({
      map: state.map,
      title: location.name,
      animation: google.maps.Animation.DROP,
      position: new google.maps.LatLng(location.lat, location.lng),
      panposition: new google.maps.LatLng(location.lat, location.lng),
      icon: '/store-locator/images/marker.png'
    });

    state.markers.push(marker);

    google.maps.event.addListener(marker, 'click', () => {
      $('.location-item.active').removeClass('active');
      openInfoWindow(location);
    });

    marker.location = location;
    location.marker = marker;

    if (state.bounds) {
      state.bounds.extend(marker.getPosition());
    }
  }

  const placeCurrentMarker = (lat, lng) => {
    if (state.currentMarker) {
      state.currentMarker.setMap(null);
    }

    const marker = new google.maps.Marker({
      map: state.map,
      animation: google.maps.Animation.DROP,
      position: new google.maps.LatLng(lat, lng),
      panposition: new google.maps.LatLng(lat, lng),
      icon: '/store-locator/images/current-marker.png',
      zIndex: 1000
    });

    if (state.bounds) {
      state.bounds.extend(marker.getPosition());
    }

    state.map.setCenter(marker.getPosition());
    state.map.setZoom(10);
    panCenter(marker.getPosition());
    state.currentMarker = marker;
  }

  const setupInfoWindow = (location) => {
    const info = new google.maps.InfoWindow({
      content: location.infoWindowContent
    });

    location.infoWindow = info;

    google.maps.event.addListener(info, 'closeclick', () => {
      $('.location-item.active').removeClass('active');
    });
  }

  const openInfoWindow = (location) => {
    if (state.currentInfoWindow && state.currentInfoWindow != location.infoWindow) {
      state.currentInfoWindow.close();
    }

    location.infoWindow.open(state.map, location.marker);

    dataLayer.push({
        'storeName' : location.marker.title,
        'event': 'markerClick'
    });

    state.currentInfoWindow = location.infoWindow;
    panCenter(location.marker.getPosition());
  }

  const bindUI = () => {
    $(document).on('mouseenter', '.locations-list .location-item', e => {
      const id = e.currentTarget.dataset.id;
      const marker = state.locations[id].marker;
      marker.setAnimation(google.maps.Animation.BOUNCE);
    }).on('mouseleave', '.locations-list .location-item', e => {
      const id = e.currentTarget.dataset.id;
      const marker = state.locations[id].marker;
      marker.setAnimation(null);
    }).on('click', '.locations-list .location-item', e => {
      const id = e.currentTarget.dataset.id;
      const marker = state.locations[id].marker;
      openInfoWindow(marker.location);

      $('.location-item.active').removeClass('active');
      $(e.currentTarget).addClass('active');
    });

    $('.store-locator-form').on('submit', e => {
      const zip = $.trim($('.store-locator-form input[name=zip]').val());
      const radius = $.trim($('.store-locator-form select[name=radius]').val());

      if (zip === '' || radius === '') {
        return false;
      }

      $('.store-locator-sidebar').addClass('geolocating');

      $.ajax({
        url: '/store-locator/locationsbyzip',
        data: { zip, radius },
        dataType: 'json',
        beforeSend: () => {
          $('.store-locator-sidebar').addClass('geolocating');
          $('.store-locator-form button').prop('disabled', true);
        },
        success: (resp) => {
          if (resp.html) {
            $('.locations-list ul').replaceWith(resp.html);
          }

          if (resp.list) {
            for (let i= 0; i < state.markers.length; i++) {
              state.markers[i].setMap(null);
            }

            state.locations = resp.list;
            placeMarkers();
          }

          if (resp.stores) {
            $('.store-locator-form select[name=store]').replaceWith(resp.stores);
          }

          placeCurrentMarker(resp.lat, resp.lng);

          Cookies.set('userlocation', {
            lat: resp.lat,
            lng: resp.lng,
            zip: resp.zip,
            radius: radius
          }, { expires: 90 });
        },
        complete: () => {
          $('.store-locator-sidebar').removeClass('geolocating');
          $('.store-locator-form button').prop('disabled', false);
        }
      });

      return false;
    });

    $(document).on('click', '.locations-list .location-item', (e) => {
      e.stopImmediatePropagation();
    });

    $('.locations-list').perfectScrollbar({
      handlers: ['drag-scrollbar', 'keyboard', 'wheel', 'touch']
    });

    $('.sidebar-toggle').on('click', () => {
      $('.store-locator-sidebar').toggleClass('visible');
    });

    $('.view-toggle').on('click', (e) => {
      $('.store-locator-wrapper').toggleClass('map-view');

      if ($('.store-locator-wrapper').hasClass('map-view')) {
        $(e.currentTarget).text('Hide Map');
      }
      else {
        $(e.currentTarget).text('Show Map');
      }
    });

    $(document).on('change', '.store-locator-form select[name=store]', (e) => {
      const storeID = $(e.currentTarget).val();

      if (storeID === '') {
        $('.locations-list ul .location-item').removeClass('even odd').show();
        $('.locations-list ul .location-item').each((index, el) => {
          state.locations[$(el).data('id')].marker.setMap(state.map);
        });
      }
      else {
        $('.locations-list ul .location-item').hide();
        $('.locations-list ul .location-item[data-store-id=' + storeID + ']').show();
        $('.locations-list ul .location-item:visible:even').addClass('even');
        $('.locations-list ul .location-item:visible:odd').addClass('odd');
        $('.locations-list ul .location-item:visible').each((index, el) => {
          state.locations[$(el).data('id')].marker.setMap(state.map);
        });
        $('.locations-list ul .location-item:hidden').each((index, el) => {
          state.locations[$(el).data('id')].marker.setMap(null);
        });
      }
    });
  }

  const getLocationsByGeocode = (lat, lng) => {
    $.ajax({
      url: '/store-locator/locationsbygeocode',
      data: { lat, lng },
      dataType: 'json',
      success: (resp) => {
        if (resp.html) {
          $('.locations-list ul').replaceWith(resp.html);
        }

        if (resp.list) {
          for (let i= 0; i < state.markers.length; i++) {
            state.markers[i].setMap(null);
          }

          state.locations = resp.list;
          placeMarkers();
        }

        if (resp.zip) {
          $('.store-locator-form input[name=zip]').val(resp.zip);
        }

        if (resp.stores) {
          $('.store-locator-form select[name=store]').replaceWith(resp.stores);
        }

        placeCurrentMarker(resp.lat, resp.lng);

        Cookies.set('userlocation', {
          lat: resp.lat,
          lng: resp.lng,
          zip: resp.zip,
          radius: 20
        }, { expires: 90 });
      },
      complete: () => {
        $('.store-locator-sidebar').removeClass('geolocating');
      }
    });
  };

  const geolocate = () => {
    const isFirefox = /firefox/i.test(navigator.userAgent);
    const cookieSet = Cookies.get('userlocation');

    if (navigator.geolocation && !isFirefox && !cookieSet) {
      $('.store-locator-sidebar').addClass('geolocating');

      const detectionSuccess = (position) => {
        getLocationsByGeocode(position.coords.latitude, position.coords.longitude);
      };

      const detectionError = () => {
        $('.store-locator-sidebar').removeClass('geolocating');
      };

      navigator.geolocation.getCurrentPosition(detectionSuccess, detectionError, { maximumAge:600000 });
    }
  };

  const init = () => {
    if (!$('.store-locator-wrapper').length) {
      return;
    }

    state.locations = window.StoreLocations;

    // If we are forcing filter by URL, don't geolocate and use faked user location object
    if (window.ForceURLFilter) {
      if (window.FilteredUserLocation) {
        state.userLocation = window.FilteredUserLocation;
        state.bounds = new google.maps.LatLngBounds();
      }
    }
    else {
      state.userLocation = Cookies.getJSON('userlocation') ? Cookies.getJSON('userlocation') : { lat: 43.423605, lng: -75.227902 };
      geolocate();
    }

    google.maps.event.addDomListener(window, 'load', setupMap);
  }

  init();

})();
