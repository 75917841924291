(function () {

  const setupBlog = () => {
    if ($('.Blog').length < 1) {
      return false;
    }

    console.log('setup Blog');

    $('.blog-form-date').on('change', 'select', (e) => {
      const $form = $(e.target).closest('form');
      if ($form.find('select[name=Month]').val() !== '' && $form.find('select[name=Year]').val() !== '') {
        $form.submit();
      }
    });
  };

  const setupBlogSubscribe = () => {
    const $form = $('.blog-subscribe-form');
    if (!$form.length) return;

    console.log('setup BlogSubscribe');

    $form.validate({
      errorPlacement: function (err, el) {
        err.insertAfter($(el).closest('.field'));
      },
      onkeyup: false,
      rules: {
        Email: {
          required: true,
          email: true
        }
      }
    });

    $form.on('submit', function (e) {
      e.preventDefault();

      if ($form.valid()) {
        $.ajax({
          type: 'post',
          dataType: 'json',
          url: $form.prop('action'),
          data: $form.serialize(),
          beforeSend: function () {
            $form.find('.success').hide();
            $form.find('.error.duplicate').hide();
            $form.find('button[type=submit]').prop('disabled', true);
          },
          success: function (resp) {
            if (resp.success) {
              $form.find('.success').show();
              $form.find('input[type=email]').val('');
            }
            else {
              if (resp.message == 'duplicate') {
                $form.find('.duplicate').show();
              }
            }
          },
          complete: function () {
            $form.find('button[type=submit]').prop('disabled', false);
          }
        });
      }
    });
  };

  setupBlog();
  setupBlogSubscribe();

})();
